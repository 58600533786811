import { Component, Input } from '@angular/core';
import { NavigationEnd, Router } from "@angular/router";
import { filter, map } from "rxjs";
import { sidebarAnimation, toolbarAnimation } from "../../animations";

@Component({
  selector: 'fokos-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  animations: [toolbarAnimation, sidebarAnimation],

})
export class ToolbarComponent {
  @Input() color: "primary" | "white" = 'primary';
  mobileContentState = 'closed';
  showMobileContent = false;

  constructor(private router: Router) {
    router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      map(e => {
        if (this.showMobileContent) {
          this.toggleMenu();
        }
      })
    ).subscribe();
  }

  toggleMenu() {
    this.mobileContentState = this.showMobileContent ? "closed" : "open";
    this.showMobileContent = !this.showMobileContent;

    const body = document.getElementById("body");
    if (body) {
      body.style.overflow = this.showMobileContent ? 'hidden' : 'initial';
    }

  }
}
