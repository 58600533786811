import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DataService } from "../../services";
import { tap } from "rxjs";

@Component({
  selector: "fokos-unsubscribe",
  templateUrl: "./unsubscribe.component.html",
  styleUrls: ["./unsubscribe.component.scss"]
})
export class UnsubscribeComponent {
  success: boolean;
  error: string;
  email: string | null;

  constructor(private route: ActivatedRoute,
              private dataService: DataService) {
    this.route.paramMap.pipe(tap(params => {
      this.email = params.get("email");
      if (this.email) {
        this.dataService.unsubscribe(this.email).subscribe(
          res => this.success = true,
          err => {
            this.success = false;
            this.error = err.error.message;
          }
        );
      }
    })).subscribe();
  }
}
