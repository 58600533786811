import { Subject } from 'rxjs';
import { ComponentRef, EmbeddedViewRef } from '@angular/core';
import { ModalContainerComponent } from '../components/modal-container/modal-container.component';
import { Modal } from './modal.model';

export class ModalRef<I, R> {
  private result = new Subject<R | undefined>();
  private readonly _componentInstance: Modal<I, R> | undefined;

  get onClose() {
    return this.result.asObservable();
  }

  get componentInstance() {
    return this._componentInstance;
  }

  constructor(
    private modalContainer: ComponentRef<ModalContainerComponent>,
    private modal: ComponentRef<Modal<I, R>> | EmbeddedViewRef<unknown>
  ) {
    if ('instance' in this.modal) {
      this._componentInstance = this.modal.instance;
      this.modal.instance.modalInstance = this;
    }
  }

  close(output?: R): void {
    this.result.next(output);
    this.destroy$();
  }

  private destroy$(): void {
    this.modal.destroy();
    this.modalContainer.destroy();
    this.result.complete();
  }
}
