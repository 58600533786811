import { Component } from '@angular/core';
import { DataService } from "../../../../services";
import { Modal } from "@frontend/shared";

@Component({
  selector: 'fokos-age-confirm',
  templateUrl: './age-confirm.component.html',
  styleUrls: ['./age-confirm.component.scss'],
})
export class AgeConfirmComponent extends Modal<void, void> {
  constructor(private dataService: DataService) {
    super();
  }

  onInjectInputs(): void {
    return;
  }

  confirm() {
    this.dataService.over18 = true;
    this.close();
  }

  dismiss() {
    window.location.href = "https://www.disneyplus.com/hu-hu/movies/gyerekjatek/68UrfxWwqyLu";
  }
}
