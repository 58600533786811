<div class="min-w-[250px] h-auto bg-primary py-6 px-12 rounded-lg relative">
  <div class="absolute top-4 right-4 cursor-pointer" (click)="close(false)">
    <img class="w-6 h-6" src="assets/icons/close-bold.svg"/>
  </div>
  <div class="py-12">
    <h1 *ngIf="input.title" class="font-bold text-white whitespace-pre">{{input.title}}</h1>
    <div class="font-semibold text-white whitespace-pre">{{input.message}}</div>
  </div>
  <div class="flex flex-row justify-between gap-6">
    <fokos-button color="accent" (click)="close(false)">Mégse</fokos-button>
    <fokos-button color="accent" (click)="close(true)">Igen</fokos-button>
  </div>
</div>
