import { Component, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from "@angular/animations";
import { v4 } from "uuid";

@Component({
  selector: 'fokos-zig-zag',
  templateUrl: './zig-zag.component.html',
  styleUrls: ['./zig-zag.component.scss'],
  animations: [
    trigger('colorChange', [
      state('*', style({'fill': '{{color}}'}), {params: {color: '#9E1817'}}),
      transition('* => *', animate('200ms')),
    ])
  ],
})
export class ZigZagComponent {
  @Input() bottom = false;
  @Input() top = false;
  @Input() color: "primary" | "light" | "dark" | "green" | "white" = "primary";

  id = v4();

  get colorCode() {
    switch (this.color) {
      case "primary":
        return '#E33B18';
      case "light":
        return '#FFDEDE';
      case "dark":
        return '#9E1817';
      case "green":
        return '#1F5451';
      case "white":
        return '#FFFFFF';
    }
  }


}
