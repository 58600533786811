import { Component } from "@angular/core";
import { Modal } from "@frontend/shared";
import { DataService } from "../../../../services";

@Component({
  selector: "fokos-cookies-modal",
  templateUrl: "./cookies-modal.component.html"
})
export class CookiesModalComponent extends Modal<null, void> {

  constructor(private dataService: DataService) {
    super();
  }

  onInjectInputs(input: null) {
    return;
  }

  confirm() {
    this.dataService.cookiesAccepted = true;
    this.close();
  }
}
