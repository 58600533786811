import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Modal, openCloseHorizontalAnimation } from "@frontend/shared";
import { first, Subscription } from "rxjs";
import { BreakpointObserver, Breakpoints, BreakpointState } from "@angular/cdk/layout";
import { DataService } from "../../../../services";
import { DataQuery } from "../../../../state/data.query";
import { GinDto } from "@frontend/data-access";
import { FormControl, Validators } from "@angular/forms";

@Component({
  selector: 'fokos-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.scss'],
  animations: [openCloseHorizontalAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class BasketComponent extends Modal<any, any> implements OnInit, OnDestroy {
  state = "closed";
  showContent = false;
  isPhone = false;
  sub = new Subscription();
  formControl = new FormControl(1, {nonNullable: true, validators: Validators.required});

  constructor(public dataQuery: DataQuery,
              private breakpointObserver: BreakpointObserver,
              private dataService: DataService) {
    super();
  }

  ngOnInit() {
    this.sub.add(this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
    ]).subscribe((state: BreakpointState) => {
      if (state.breakpoints[Breakpoints.XSmall]) {
        this.isPhone = true;
      }
      if (state.breakpoints[Breakpoints.Small]) {
        this.isPhone = false;
      }

      if (this.state === "open" && this.isPhone) {
        this.state = "openMobile";
      }
      if (this.state === "openMobile" && !this.isPhone) {
        this.state = "open";
      }
    }));
    this.state = this.isPhone ? "openMobile" : "open";
    this.sub.add(this.dataQuery.basketItem$.pipe(first()).subscribe(
      item => this.formControl.patchValue(item.count, {emitEvent: false})
    ));
  }

  onInjectInputs(): void {
    return;
  }

  updateBasket(gin: GinDto) {
    if (this.formControl.valid) {
      this.dataService.updateBasket(gin, this.formControl.value);
    }
  }

  continue() {
    this.state = "closed";
    setTimeout(() => this.close(), 250);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
