import { Component, ElementRef, HostListener, Input, OnChanges, OnInit, Renderer2, SimpleChanges, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'fokos-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ButtonComponent implements OnInit, OnChanges {
  @Input() color: 'accent' | 'primary' | 'primary-full' = 'primary';
  @Input() type: 'submit' | 'reset' | 'button' = 'button';
  @Input() disabled = false;

  constructor(
    private renderer: Renderer2,
    private hostElement: ElementRef) {

  }

  ngOnInit(): void {
    this.updateButtonState();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.disabled){
      this.renderer.addClass(this.hostElement.nativeElement, 'disabled');
    } else {
      this.renderer.removeClass(this.hostElement.nativeElement, 'disabled');
    }
    return;
    // this.deleteClasses();
    // this.updateButtonState();
    // if (changes.disabled) {
    //   this.refreshDisabledClass(this.isButtonDisabed(), this.buttonType);
    // }
  }

  updateButtonState() {
    this.renderer.addClass(this.hostElement.nativeElement, 'button-wrapper');
    this.renderer.addClass(this.hostElement.nativeElement, this.color);
    this.disabled && this.renderer.addClass(this.hostElement.nativeElement, 'disabled');
  }
}
