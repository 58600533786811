<fokos-zig-zag [top]="true" color="dark"></fokos-zig-zag>
<div class="section bg-primary-dark">
  <div class="content flex gap-6">
    <div class="flex flex-col gap-6 justify-center flex-1 text-primary-light">
      <h2>Különleges összetevők</h2>
      <div class="flex flex-col justify-between gap-8 laptop:grid laptop:grid-cols-4"
           [ngClass]="{'gap-12': showMore}">
        <div class="flex flex-col tablet:items-center tablet:text-center gap-2">
          <div class="h-[200px] w-[200px] flex items-center justify-center self-center bg-paca">
            <img class="h-[150px] w-[150px]" src="assets/images/homoktovis.png" />
          </div>
          <h3 class="tablet:mt-12">Kecskeméti Bio-Homoktövis</h3>
          <div class="text-sm">itt megtalálsz mindent, amit Nagy Ervinné Zsuzsa közel 30 éve működő biogazdálkodásáról
            tudni szeretnél: <a class="light" href="http://biohomoktovis.hu/site/page.php?5" target="_blank">biohomoktovis.hu</a>
          </div>
          <img class="w-4 cursor-pointer tablet:hidden" [src]="showMore? ARROW_UP : ARROW_DOWN"
               (click)="showMore = !showMore" />
        </div>
        <div [@openClose]="animationState(showMore)"
             class="flex flex-col tablet:items-center tablet:text-center gap-2">
          <div class="h-[200px] w-[200px] flex items-center justify-center self-center bg-paca">
            <img class="h-[150px] w-[150px]" src="assets/images/levendula.png" />
          </div>
          <h3 class="tablet:mt-12">Ménteleki Levendula</h3>
          <div class="text-sm">többgenerációs, nagy múltú családi vállalkozás, amely hasonlóan az eddigi működéséhez
            nagy tervekkel tekint a jövőbe is
          </div>
        </div>

        <div [@openClose]="animationState(showMore)" class="flex flex-col tablet:items-center tablet:text-center gap-2">
          <div class="h-[200px] w-[200px] flex items-center justify-center self-center bg-paca">
            <img class="h-[150px] w-[150px]" src="assets/images/akacvirag.png" />
          </div>
          <h3 class="tablet:mt-12">Hetényi Akácvirág</h3>
          <div class="text-sm">ez a legkülönlegesebb összetevő, ezt saját magam, a kis kézikacsómmal gyűjtöm nektek egy
            barátom végtelen erdejéből
          </div>
        </div>

        <div [@openClose]="animationState(showMore)" class="flex flex-col tablet:items-center tablet:text-center gap-2">
          <div class="h-[200px] w-[200px] flex items-center justify-center self-center bg-paca">
            <img class="h-[150px] w-[150px]" src="assets/images/citrom.png" />
          </div>
          <h3 class="tablet:mt-12">Lajosmizsei Citrom</h3>
          <div class="text-sm">’87 óta foglalkoznak zöldség- és gyümölcstermesztéssel, 10 éve kezdték a kezeletlen
            Meyer-citromuk terjesztését. <a class="light"
                                           href="https://telex.hu/video/2022/12/25/citrom-ultetveny-bio-hazai-lajosmizse-szoros-es-kisjuhasz"
                                           target="_blank">Itt megnézheted őket!</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
