import { Component } from '@angular/core';
import { DataService } from "../../../../services";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SubscriptionDto } from "@frontend/data-access";

@Component({
  selector: 'fokos-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
})
export class SubscriptionComponent {
  constructor(private service: DataService) {
  }

  subscribed = false;
  form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    name: new FormControl('', [Validators.required]),
    dataManagement: new FormControl(false, [Validators.required, Validators.requiredTrue]),
  });

  ngOnInit() {
    this.form.valueChanges.subscribe(() => this.subscribed = false);
  }

  subscribe() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.service.subscribe(this.form.value as SubscriptionDto).subscribe(
        res => {
          this.form.reset();
          this.subscribed = true;
        }
      );
    }
  }

  get dataManagement(){
    return this.form.controls.dataManagement;
  }
}
