import { Component } from '@angular/core';
import { openCloseAnimation } from "@frontend/shared";
import { DataQuery } from "../../../../state/data.query";

@Component({
  selector: 'fokos-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
  animations: [openCloseAnimation],
})
export class AboutUsComponent {
  ARROW_UP = "assets/icons/arrow-up-green.svg";
  ARROW_DOWN = "assets/icons/arrow-down-green.svg";
  showMore = false;

  constructor(public dataQuery: DataQuery) {
  }

  animationState(state: boolean) {
    return state ? 'open' : 'closed';
  }

}
