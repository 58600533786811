import { InvoiceDataDto } from "./invoice-data.dto";
import { ShippingDataDto } from "./shipping-data.dto";
import { CouponDto } from "./coupon.dto";

export enum PayMode {
  SimplePay = "SIMPLEPAY",
  Cash = "CASH",
}

export interface PlaceOrderEntity {
  ginId: string;
  count: number;
}

export interface PlaceOrderDto {
  coupon: CouponDto | null;

  invoiceData: InvoiceDataDto;
  shippingData: ShippingDataDto;
  entities: PlaceOrderEntity[];
  payMode: PayMode;
  totalPrice: number;
}
