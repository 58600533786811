import { Component } from '@angular/core';
import { Modal } from "../../modal-service";

export class ConfirmModalInput {
  title?: string;
  message: string;
}

@Component({
  selector: 'fokos-error-modal',
  templateUrl: './confirm-modal.component.html',
})
export class ConfirmModalComponent extends Modal<ConfirmModalInput, boolean> {
  input: ConfirmModalInput;

  onInjectInputs(input: ConfirmModalInput): void {
    this.input = input;
  }
}
