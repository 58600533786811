import { Query } from "@datorama/akita";
import { Injectable } from "@angular/core";
import { DataState, DataStore } from "./data.store";
import { map } from "rxjs";

@Injectable({providedIn: 'root'})
export class DataQuery extends Query<DataState> {
  constructor(store: DataStore) {
    super(store);
  }

  cookiesAccepted$ = this.select().pipe(
    map(state => state.cookiesAccepted),
  );
  over18$ = this.select().pipe(
    map(state => state.over18),
  );

  greeting$ = this.select().pipe(
    map(state => state.greeting),
  );

  about$ = this.select().pipe(
    map(state => state.about),
  );

  gin$ = this.select().pipe(
    map(state => state.ginDetails),
  );

  basketItem$ = this.select().pipe(
    map(state => state.basket[0]),
  );

  activeCoupon$ = this.select().pipe(
    map(state => state.activeCoupon ?? null)
  );
}
