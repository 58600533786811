<div class="w-full fixed min-h-[80px] top-0 z-10">
  <div [@toolbar]="color" class="p-2 bg-primary flex justify-center">
    <div class="w-full content">
      <div class="flex justify-between items-center min-h-[64px] gap-6">
        <img (click)="showMobileContent && toggleMenu()" class="cursor-pointer" routerLink="/" src="assets/logo.svg"/>
        <div class="flex-auto"></div>
        <ng-content select="[desktopItems]"></ng-content>
        <img *ngIf="!showMobileContent" src="assets/icons/menu.svg" class="palmtop:hidden" (click)="toggleMenu()">
        <img *ngIf="showMobileContent" class="cursor-pointer" (click)="toggleMenu()" src="assets/icons/close.svg"/>
      </div>
      <div [@openCloseSidebar]="mobileContentState" class="w-full collapse" [ngClass]="{'mt-8': showMobileContent}">
        <ng-content select="[mobileItems]"></ng-content>
      </div>
    </div>
  </div>
  <!--  <fokos-zig-zag *ngIf="color==='white'" [bottom]="true" color="white"></fokos-zig-zag>-->
  <fokos-zig-zag [bottom]="true" [color]="color"></fokos-zig-zag>
</div>
