<!--<div [ngClass]="{'zig-zag-bottom': bottom, 'zig-zag-top': top}" [class]="color">-->
<!--  <ng-content></ng-content>-->
<!--</div>-->
<div class="relative bg-none">
  <!--  <div *ngIf="top" [@colorChange]="{value: color, params: {backgroundImage: background}}" class="zig-zag-top"-->
  <!--       [class]="color"></div>-->
  <svg *ngIf="top" class="zig-zag-top" [@colorChange]="{value: color, params: {color: colorCode}}"
       xmlns="http://www.w3.org/2000/svg" fill="#9E1817" height="22">
    <defs>
      <pattern [id]="id+'Top'" patternUnits="userSpaceOnUse" width="32" height="24">
        <path d="M0 22L32 22L16 0L0 22Z"/>
        <!--        <path d="M16 7.65083L23.163 17.5L8.83697 17.5L16 7.65083Z"  stroke-width="9"/>-->
      </pattern>
    </defs>
    <rect width="100%" height="100%" [attr.fill]="'url(#'+id+'Top)'"/>
  </svg>
  <ng-content></ng-content>
  <!--  <div *ngIf="bottom" [@colorChange]="{value: color, params: {backgroundImage: background}}" class="zig-zag-bottom" [class]="color"></div>-->
  <svg *ngIf="bottom" class="zig-zag-bottom" [@colorChange]="{value: color, params: {color: colorCode}}"
       xmlns="http://www.w3.org/2000/svg" color="#9E1817"
       width="100%" height="22">
    <defs>
      <pattern [id]="id+'Bottom'" patternUnits="userSpaceOnUse" width="32" height="24">
        <path d="M32 0H0L16 22L32 0Z"/>
        <!--        <path d="M16 14.3492L8.83697 4.5H23.163L16 14.3492Z"  stroke-width="9"/>-->

      </pattern>
    </defs>
    <rect width="100%" height="100%" [attr.fill]="'url(#'+id+'Bottom)'"/>
  </svg>
</div>
