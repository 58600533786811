export enum DeliveryMode {
  GLS = "GLS",
  Foxpost = "FOXPOST",
  Fokos = "FOKOS",
  Personal = "PERSONAL",
}

export interface Delivery {
  deliveryMode: DeliveryMode;
  price: number;
  disabled?: boolean;
}

export const DeliveryModes: Delivery[] = [
  {
    deliveryMode: DeliveryMode.Foxpost,
    price: 0,
    disabled: true
  },
  {
    deliveryMode: DeliveryMode.GLS,
    price: 0,
    disabled: true
  },
  {
    deliveryMode: DeliveryMode.Fokos,
    price: 990
  }, {
    deliveryMode: DeliveryMode.Personal,
    price: 0,
  },
];

export interface ShippingDataDto {
  name: string;
  address: string;
  phone: string;
  email: string;
  comment?: string;
  delivery: Delivery;
}
