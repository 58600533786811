<fokos-zig-zag [top]="true" color="green"></fokos-zig-zag>
<div class="section bg-green">
  <div class="content flex gap-6">
    <div class="flex flex-col gap-6 flex-1 text-white">
      <h2 class="text-primary-light">Ha szívesen lennél te is a partnerünk, itt jelezd!</h2>
      <h4 class="text-white">Szeretnél Fokost forgalmazni? Írj nekünk, mert a legjobb áron tőlünk tudod beszerezni!</h4>
      <div class="flex flex-col gap-6 self-center w-full palmtop:w-[500px]">
        <fokos-input color="accent" [error]="form.controls.name.touched && form.controls.name.invalid">
          <input [formControl]="form.controls.name" type="text" placeholder="név" required>
        </fokos-input>
        <fokos-input color="accent" [error]="form.controls.email.touched && form.controls.email.invalid">
          <input [formControl]="form.controls.email" type="text" placeholder="email-cím" required>
        </fokos-input>
        <fokos-input color="accent" [error]="form.controls.text.touched && form.controls.text.invalid" type="textarea">
          <textarea [formControl]="form.controls.text" class="h-48" placeholder="üzenet" required></textarea>
        </fokos-input>
        <fokos-button color="accent" class="self-start" (click)="sendEmail()">{{sent ? 'elküldve' : 'küldés'}}</fokos-button>
      </div>

    </div>
  </div>
</div>
