import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { persistState } from "@datorama/akita";
import { enableProdMode } from "@angular/core";
import { environment } from "./environments/environment";

function loadGoogleAnalytics(trackingID: string): void {

  let gaScript = document.createElement('script');
  gaScript.setAttribute('async', 'true');
  gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${ trackingID }`);

  let gaScript2 = document.createElement('script');
  gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${ trackingID }\');`;

  // @ts-ignore
  document.documentElement.firstChild.appendChild(gaScript);
  // @ts-ignore
  document.documentElement.firstChild.appendChild(gaScript2);
}

loadGoogleAnalytics(environment.g_tag)
if (environment.production) {
  enableProdMode();
}
const storage = persistState();

const providers = [{ provide: "persistStorage", useValue: storage }];

platformBrowserDynamic(providers)
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
