<fokos-zig-zag [top]="true" color="light"></fokos-zig-zag>
<div class="section bg-primary-light">
  <div *ngIf="dataQuery.about$ | async as data" class="content flex flex-col-reverse gap-24 desktop:flex-row">
    <div class="flex flex-col justify-center flex-2 text-green min-w-[250px] palmtop:min-w-[400px]">
      <h2 class="mb-6">{{data.title}}</h2>
      <div class="inline-block">
        <p [innerHTML]="data.description"></p>
        <p class="mb-3" [@openClose]="animationState(showMore)" [innerHTML]="data.moreDescription"></p>
      </div>
      <img class="w-4 cursor-pointer" [src]="showMore? ARROW_UP : ARROW_DOWN"
           (click)="showMore = !showMore" />
    </div>
    <div class="flex-1 flex items-center justify-center">
      <img [src]="data.image">
    </div>
  </div>
</div>
