import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from "@angular/common";

@Pipe({
  name: 'fokosNumber'
})
export class NumberPipe implements PipeTransform {

  constructor(private numberPipe: DecimalPipe) {
  }

  transform(value: number | string, ...args: unknown[]): unknown {
    const transformedValue = this.numberPipe.transform(value);
    if (transformedValue) {
      return transformedValue.replace('.', ',');
    }
    return value;
  }

}
