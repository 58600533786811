<fokos-toolbar #toolbarComponent [color]="toolbarColor">
  <div desktopItems class="gap-10 text-button font-bold items-center hidden palmtop:flex">
    <fokos-toolbar-item routerLink="" fragment="rolunk">rólunk</fokos-toolbar-item>
    <fokos-toolbar-item routerLink="" fragment="vasarlas">shop</fokos-toolbar-item>
    <fokos-toolbar-item routerLink="" fragment="viszonteladoknak">viszonteladóknak</fokos-toolbar-item>
    <fokos-toolbar-item routerLink="/kosar" class="relative">
      <img src="assets/icons/cart.svg"/>
      <div *ngIf="(dataQuery.basketItem$ | async)?.count ?? 0 > 0"
        class="bg-primary-dark rounded-full text-white text-xs font-normal
           absolute right-[-8px] top-[-8px] w-5 h-5
           px-2 flex items-center justify-center">
        {{(dataQuery.basketItem$ | async)?.count ?? 0}}
      </div>
    </fokos-toolbar-item>
  </div>
  <div mobileItems class="flex flex-col items-start gap-6 font-semibold">
    <fokos-toolbar-item (click)="toolbarComponent.toggleMenu()" routerLink="" fragment="rolunk">rólunk</fokos-toolbar-item>
    <fokos-toolbar-item (click)="toolbarComponent.toggleMenu()" routerLink="" fragment="vasarlas">shop</fokos-toolbar-item>
    <fokos-toolbar-item (click)="toolbarComponent.toggleMenu()" routerLink="" fragment="viszonteladoknak">viszonteladóknak</fokos-toolbar-item>
    <fokos-toolbar-item (click)="toolbarComponent.toggleMenu()" routerLink="/kosar">kosár</fokos-toolbar-item>
  </div>
</fokos-toolbar>
<div class="routing">
  <router-outlet></router-outlet>
</div>
<fokos-footer></fokos-footer>
