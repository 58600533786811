import { Injectable } from "@angular/core";

declare let gtag: Function;

@Injectable()
export class GoogleAnalyticsService {
  public eventEmitter(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string | null = null,
    eventValue: number | null = null) {
    gtag('event', eventName, {
      eventCategory: eventCategory,
      eventLabel: eventLabel,
      eventAction: eventAction,
      eventValue: eventValue
    });
  }

  public pageViewEventEmitter(
    eventName: string,
    eventObject: any) {
    gtag('event', eventName, eventObject);
  }

  public cartEventEmitter(
    eventName: string,
    value: number,
    items: any[]) {
    gtag('event', eventName, {
      currency: "HUF",
      value,
      items
    });
  }

  public purchaseEventEmitter(
    transaction_id: string,
    value: number,
    coupon: string,
    shipping: number,
    items: any[]) {
    gtag('event', 'purchase', {
      transaction_id: transaction_id,
      currency: "HUF",
      value: value,
      coupon,
      shipping,
      items: items
    });
  }
}
