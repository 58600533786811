import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from "./pages/home/home.component";
import { BasketOverviewComponent } from "./pages/basket-overview/basket-overview.component";
import { OrderComponent } from "./pages/order/order.component";
import { ConfirmationComponent } from "./pages/confirmation/confirmation.component";
import { UnsubscribeComponent } from "./pages/unsubscribe/unsubscribe.component";

const routes: Routes = [
  {path: '', pathMatch: 'full', component: HomeComponent, data: {title: "FokosGin"}},
  {path: 'kosar', component: BasketOverviewComponent, data: {title: "Kosár"}},
  {path: 'megrendeles', component: OrderComponent, data: {title: "Megrendelés"}},
  {path: 'visszaigazolas', component: ConfirmationComponent, data: {title: "Visszaigazolás"}},
  {path: 'visszaigazolas/:id', component: ConfirmationComponent, data: {title: "Visszaigazolás"}},
  {path: 'leiratkozas/:email', component: UnsubscribeComponent, data: {title: "Leiratkozás"}},
  {path: '**', redirectTo: ''},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
