import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: 'fokos-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ]
})
export class CheckboxComponent implements ControlValueAccessor {
  private _value = false;
  set value(val: boolean) {  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
    this._value = val;
    this.onChange(val);
    this.onTouch(val);
  }

  get value() {
    return this._value;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouch: any = () => {
  };

  writeValue(value: boolean) {
    this.value = value;
  }

  onClick() {
    this.value = !this.value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
}
