import { ModalRef } from './modal-ref.model';

export abstract class Modal<Input, Return> {
  modalInstance!: ModalRef<Input, Return>;

  abstract onInjectInputs(inputs: Input): void;

  close(output?: Return): void {
    this.modalInstance?.close(output);
  }
}
