import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { ZigZagModule } from '../zig-zag/zig-zag.module';
import { FooterItemComponent } from './footer-item/footer-item.component';

@NgModule({
  declarations: [FooterComponent, FooterItemComponent],
  imports: [CommonModule, ZigZagModule],
  exports: [FooterComponent],
})
export class FooterModule {}
