<div class="flex justify-center h-auto bg-grey p-12 laptop:p-24 relative">
  <div class="flex flex-col gap-6 laptop:gap-12">
    <h2>Az Oldal Sütiket Használ <span class="icon-font">🍪</span></h2>
    <p>További részletek: <b class="uppercase"><a href="assets/docs/Fokos_Adatkezelesi.pdf" target="_blank">Adatkezelési
      tájékoztató</a></b></p>
    <div class="flex flex-row justify-center gap-6">
      <fokos-button (click)="confirm()">elfogadás</fokos-button>
    </div>
  </div>
</div>
