import { Component, HostListener, ViewContainerRef } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router, RouterState } from "@angular/router";
import { BackgroundService } from "./services";
import { AgeConfirmComponent } from "./pages/home/components/age-confirm/age-confirm.component";
import { filter, firstValueFrom, map } from "rxjs";
import { GoogleAnalyticsService, LoggerService, ModalService } from "@frontend/shared";
import { DataQuery } from "./state/data.query";
import { Title } from "@angular/platform-browser";
import { CookiesModalComponent } from "./pages/home/components/cookies/cookies-modal.component";
import { environment } from "../environments/environment";

@Component({
  selector: "fokos-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  title = "fokos";
  toolbarColor: "primary" | "white" = "primary";

  constructor(
    private viewContainer: ViewContainerRef,
    private modalService: ModalService,
    private backgroundService: BackgroundService,
    private router: Router,
    public dataQuery: DataQuery,
    private titleService: Title,
    protected gaService: GoogleAnalyticsService
  ) {
    LoggerService.PRODUCTION = environment.production;
    this.modalService.setViewContainerRef(this.viewContainer);
    this.handlePopups();
    this.handleRouteEvents();
    this.router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      map(e => {
          if (!e.url.includes("#"))
            window.scrollTo({ top: 0 });
          if (e.url === "/" || e.url.startsWith("/#")) {
            this.toolbarColor = "primary";
          } else {
            this.toolbarColor = "white";
          }
        }
      )).subscribe();
  }

  handleRouteEvents() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const title = this.getTitle(this.router.routerState, this.router.routerState.root).join("-");
        LoggerService.log(title);
        this.titleService.setTitle(title);
      }
    });
  }

  getTitle(state: RouterState, parent: ActivatedRoute): string[] {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data["title"]) {
      data.push(parent.snapshot.data["title"]);
    }
    if (state && parent && parent.firstChild) {
      data.push(...this.getTitle(state, parent.firstChild));
    }
    return data;
  }


  @HostListener("window:scroll", ["$event"])
  checkScroll() {
    const componentPosition = document.getElementsByTagName("fokos-about-us")[0]?.getBoundingClientRect().top;

    if (componentPosition > 110) {
      if (this.toolbarColor !== "primary") {
        this.toolbarColor = "primary";
      }
    } else {
      if (this.toolbarColor !== "white") {
        this.toolbarColor = "white";
      }
    }
  }

  async handlePopups() {
    const cookiesRef = await this.handleCookies();
    if (cookiesRef) {
      cookiesRef.onClose.subscribe(() => this.handleUnder18());
    } else {
      await this.handleUnder18();
    }
  }

  async handleCookies() {
    const cookiesAccepted = await firstValueFrom(this.dataQuery.cookiesAccepted$);
    if (!cookiesAccepted) {
      return this.modalService.openWithRef<CookiesModalComponent, null, void>(CookiesModalComponent, null, { position: "bottom", fullWidth: true });
    }
    return;
  }

  async handleUnder18() {
    const over18 = await firstValueFrom(this.dataQuery.over18$);
    if (!over18) {
      return this.modalService.open<AgeConfirmComponent, void, void>(AgeConfirmComponent);
    }
    return;
  }
}
