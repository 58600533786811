import { Store, StoreConfig } from "@datorama/akita";
import { AboutDto, CouponDto, GinDto, GreetingDto, OrderEntityDto } from "@frontend/data-access";
import { Injectable } from "@angular/core";
import produce from "immer";

export interface DataState {
  cookiesAccepted: boolean;
  over18: boolean;
  greeting: GreetingDto | undefined;
  about: AboutDto | undefined;
  ginDetails: GinDto | undefined;
  basket: Omit<OrderEntityDto, 'id'>[];
  activeCoupon: CouponDto | undefined;
}

function createInitialState(): DataState {
  return {about: undefined, basket: [],cookiesAccepted: false, ginDetails: undefined, greeting: undefined, over18: false, activeCoupon: undefined};
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'data', producerFn: produce})
export class DataStore extends Store<DataState> {
  constructor() {
    super(createInitialState());
  }
}
