import { Component } from '@angular/core';
import { Router, Scroll } from "@angular/router";
import { filter, map } from "rxjs";
import { DataService } from "../../services";

@Component({
  selector: 'fokos-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  constructor(private router: Router,
              private dataService: DataService) {
    this.dataService.getAll();
    const yOffset = 90;
    this.router.events.pipe(
      filter((e): e is Scroll => e instanceof Scroll),
      map(e => {
        // e is now NavigationEnd
        if (!e.anchor)
          return;

        const element = document.getElementById(e.anchor);
        if (!element)
          return;

        const y = element.getBoundingClientRect().top + window.scrollY - yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
      })
    ).subscribe();
  }
}
