import { Pipe, PipeTransform } from '@angular/core';
import { DeliveryMode, PayMode } from "@frontend/data-access";

@Pipe({
  name: 'fokosPayMode'
})
export class PayModePipe implements PipeTransform {

  transform(mode: PayMode, ...args: unknown[]): unknown {
    switch (mode) {
      case PayMode.SimplePay:
        return "Kártyás fizetés";
      case PayMode.Cash:
        return "Készpénzes fizetés";
    }
  }

}
