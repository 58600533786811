import { Component, OnDestroy } from '@angular/core';
import { ModalService, openCloseAnimation } from "@frontend/shared";
import { BasketComponent } from "../basket/basket.component";
import { DataService } from "../../../../services";
import { DataQuery } from "../../../../state/data.query";
import { FormControl, Validators } from "@angular/forms";
import { GinDto } from "@frontend/data-access";
import { Subscription } from "rxjs";

@Component({
  selector: 'fokos-gin-details',
  templateUrl: './gin-details.component.html',
  styleUrls: ['./gin-details.component.scss'],
  animations: [openCloseAnimation],
})
export class GinDetailsComponent implements OnDestroy {
  ARROW_UP = "assets/icons/arrow-up.svg";
  ARROW_DOWN = "assets/icons/arrow-down.svg";
  showMoreStorage = false;
  showMoreShipping = false;
  sub = new Subscription();
  formControl = new FormControl(1, {nonNullable: true, validators: Validators.required});

  constructor(public dataQuery: DataQuery,
              private modalService: ModalService,
              private dataService: DataService) {
  }

  updateBasket(gin: GinDto) {
    if (this.formControl.valid) {
      this.dataService.updateBasket(gin, this.formControl.value, true);
      this.openModal();
    }
  }

  animationState(state: boolean) {
    return state ? 'open' : 'closed';
  }

  openModal() {
    this.modalService.open<BasketComponent, any, any>(BasketComponent, null, {
      fullHeight: true,
      position: 'right'
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
