import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import {
  ButtonModule,
  CheckboxModule,
  CurrencyPipe,
  ErrorInterceptor,
  FooterModule,
  GoogleAnalyticsService,
  InputModule,
  ModalService,
  ModalsModule,
  NumberPipe,
  NumberStepperModule,
  PipesModule,
  RadioModule,
  ToolbarModule,
  ZigZagModule,
} from '@frontend/shared';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { IngredientsComponent } from './pages/home/components/ingredients/ingredients.component';
import { GinDetailsComponent } from './pages/home/components/gin-details/gin-details.component';
import { GreetingComponent } from './pages/home/components/greeting/greeting.component';
import { AboutUsComponent } from './pages/home/components/about-us/about-us.component';
import { SubscriptionComponent } from './pages/home/components/subsciption/subscription.component';
import { ContactComponent } from './pages/home/components/contact/contact.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BasketComponent } from './pages/home/components/basket/basket.component';
import { OrderComponent } from './pages/order/order.component';
import { ConfirmationComponent } from './pages/confirmation/confirmation.component';
import { BasketOverviewComponent } from './pages/basket-overview/basket-overview.component';
import { AgeConfirmComponent } from './pages/home/components/age-confirm/age-confirm.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DecimalPipe } from '@angular/common';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { ApiInterceptor } from './interceptors/api.interceptor';
import { environment } from '../environments/environment';
import { CookiesModalComponent } from './pages/home/components/cookies/cookies-modal.component';
import { UnsubscribeComponent } from './pages/unsubscribe/unsubscribe.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    IngredientsComponent,
    GinDetailsComponent,
    GreetingComponent,
    AboutUsComponent,
    SubscriptionComponent,
    ContactComponent,
    BasketComponent,
    BasketOverviewComponent,
    OrderComponent,
    ConfirmationComponent,
    AgeConfirmComponent,
    CookiesModalComponent,
    UnsubscribeComponent,
  ],
  imports: [
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToolbarModule,
    ButtonModule,
    InputModule,
    ReactiveFormsModule,
    CheckboxModule,
    RouterModule,
    FooterModule,
    ZigZagModule,
    FormsModule,
    ModalsModule,
    NumberStepperModule,
    RadioModule,
    PipesModule,
  ],
  providers: [
    GoogleAnalyticsService,
    ModalService,
    DecimalPipe,
    NumberPipe,
    CurrencyPipe,
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
