<div [@openCloseHorizontal]="state"
     (@openCloseHorizontal.done)="showContent = true"
     class="bg-white h-full flex flex-col gap-6">
  <ng-container *ngIf="dataQuery.basketItem$ | async as data">
    <div *ngIf="showContent" class="flex-auto flex flex-col items-center gap-10 ">
      <div></div>
      <h1 class="text-neutral-dark">Kosár</h1>
      <div class="flex flex-col gap-4">
        <img class="w-48" src="assets/images/glass.png" />
        <h2>{{data.gin.name}}</h2>
        <p>{{data.gin.capacity | fokosNumber}} L</p>
        <fokos-number-stepper
          class="w-[110px]"
          [formControl]="formControl"
          (ngModelChange)="updateBasket(data.gin)"
          [min]="1"
          suffix="db"
        ></fokos-number-stepper>
      </div>
    </div>
    <div *ngIf="showContent" class="bg-grey py-10 flex-col flex gap-6 items-center">
      <div class="flex justify-between gap-10">
        <span>részösszeg:</span><b>{{(data.gin.price * data.count) | fokosCurrency}}</b>
      </div>
      <div class="flex flex-col gap-4">
        <fokos-button color="accent" (click)="continue()">vissza a főoldalra</fokos-button>
        <fokos-button color="primary-full" (click)="continue()" routerLink="/kosar">kosár megtekintése</fokos-button>
      </div>
    </div>
  </ng-container>
</div>
