<!-- Intro -->
<fokos-greeting></fokos-greeting>

<!-- Bemutatkozás -->
<fokos-about-us id="rolunk"></fokos-about-us>

<!-- Összetevők -->
<fokos-ingredients id="osszetevok"></fokos-ingredients>

<!-- Gin -->
<fokos-gin-details id="vasarlas"></fokos-gin-details>

<!-- Kapcsolat -->
<fokos-contact id="viszonteladoknak"></fokos-contact>

<!-- Hírlevél -->
<fokos-subscription id="feliratkozas"></fokos-subscription>
