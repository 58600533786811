import { Component, forwardRef, Input, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: 'fokos-number-stepper',
  templateUrl: './number-stepper.component.html',
  styleUrls: ['./number-stepper.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberStepperComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NumberStepperComponent),
      multi: true
    }
  ]
})
export class NumberStepperComponent implements ControlValueAccessor {
  @Input() public color: 'primary' | 'accent' = 'primary';
  @Input() public error = false;
  @Input() public title: string | null = null;
  @Input() public placeholder: string | null = null;
  @Input() public suffix: string | null = null;
  @Input() public prefix: string | null = null;
  @Input() public min: number | null = null;
  @Input() public max: number | null = null;
  @Input() public step = 1;


  private _value: any | null | undefined;
  set value(val: any | null | undefined) {  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
    this._value = val;
    this.onChange(val);
    this.onTouch(val);
  }

  get value() {
    return this._value;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouch: any = () => {
  };

  writeValue(value: any | null | undefined) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  validate({value}: FormControl) {
    const isNotValid = isNaN(value);
    return isNotValid && {
      invalid: true
    };
  }

  increment(step: number) {
    this.value = Math.min(Math.max(this.value + step, this.min ?? Number.MIN_SAFE_INTEGER), this.max ?? Number.MAX_SAFE_INTEGER);
    this.onChange(this.value);
  }
}
