import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ModalContainerComponent } from "./modal-service/components/modal-container/modal-container.component";
import { ConfirmModalComponent, ErrorModalComponent, LoadingModalComponent } from "./modals";
import { ButtonModule } from "../components";

@NgModule({
  declarations: [
    ModalContainerComponent,
    ErrorModalComponent,
    ConfirmModalComponent,
    LoadingModalComponent
  ],
  imports: [CommonModule, ButtonModule],
  exports: [
    ModalContainerComponent,
    ErrorModalComponent,
    ConfirmModalComponent,
    LoadingModalComponent
  ]
})
export class ModalsModule {
}
