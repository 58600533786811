import { Component, OnDestroy } from '@angular/core';
import { DataService } from "../../../../services";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ContactUsDto } from "@frontend/data-access";
import { Subscription } from "rxjs";

@Component({
  selector: 'fokos-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnDestroy {
  sent = false;
  form = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    text: new FormControl('', Validators.required),
  });
  sub = new Subscription();

  constructor(private service: DataService) {
    this.sub.add(this.form.valueChanges.subscribe(() => this.sent = false));
  }


  sendEmail() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.service.contact(this.form.value as ContactUsDto).subscribe(res => {
        this.form.reset();
        this.sent = true;
      });
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
