<div
  class="inputContainer"
  [class.focus]="!disabled && !error && focus"
  [class.disabled]="disabled"
  [class.error]="!disabled && error"
>
  <div class="title" [ngClass]="color">
    <ng-content select="[fokosInputTitle]"></ng-content>
  </div>
  <div
    class="inputContent {{color}}"
    [ngClass]="{'noPadding': paddingOff}" x
  >
    <div class="prefix">
      <ng-content select="[fokosInputPrefix]"></ng-content>
    </div>
    <ng-content></ng-content>
    <div class="suffix">
      <ng-content select="[fokosInputSuffix]"></ng-content>
    </div>
    <div class="numberStepper">
      <ng-content select="[fokosNumberInputStepper]"></ng-content>
    </div>
    <div class="counter">
      <ng-content select="[fokosCounter]"></ng-content>
    </div>
  </div>
  <div class="errorMessage">
    <ng-content select="[fokosError]"></ng-content>
  </div>
</div>
