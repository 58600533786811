import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CurrencyPipe } from "./currency.pipe";
import { DeliveryModePipe } from "./delivery-mode.pipe";
import { NumberPipe } from "./number.pipe";
import { PayModePipe } from "./pay-mode.pipe";
import { StatusPipe } from "./status.pipe";


@NgModule({
  declarations: [
    CurrencyPipe,
    DeliveryModePipe,
    NumberPipe,
    PayModePipe,
    StatusPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CurrencyPipe,
    DeliveryModePipe,
    NumberPipe,
    PayModePipe,
    StatusPipe
  ]
})
export class PipesModule {
}
