import { Pipe, PipeTransform } from "@angular/core";
import { OrderStatus } from "@frontend/data-access";

@Pipe({
  name: "fokosStatus"
})
export class StatusPipe implements PipeTransform {
  transform(status: OrderStatus | string, ...args: unknown[]): string | undefined {
    switch (status) {
      case OrderStatus.Start:
      case OrderStatus.Init:
        return "Létrejött";
      case OrderStatus.Failed:
      case OrderStatus.Fail:
      case OrderStatus.NotAuthorized:
        return "Sikertelen";
      case OrderStatus.Timeout:
        return "Időtúllépés";
      case OrderStatus.Canceled:
        return "Megszakított";
      case OrderStatus.InPayment:
        return "Folyamatban";
      case OrderStatus.InFraud:
        return "Vizsgálat alatt";
      case OrderStatus.Authorized:
        return "Sikeres authorizáció a kártyaadatok megadása után";
      case OrderStatus.Fraud:
        return "Csalás gyanú";
      case OrderStatus.Reserved:
        return "Zárolt összeg visszafordítva";
      case OrderStatus.Refund:
        return "Visszatérítés";
      case OrderStatus.Finished:
      case OrderStatus.Success:
        return "Sikeres";
      case OrderStatus.Deleted:
        return "Törölt";
      default:
        return "-";
    }
  }

}
