import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from "@angular/common";

@Pipe({
  name: 'fokosCurrency'
})
export class CurrencyPipe implements PipeTransform {

  constructor(private numberPipe: DecimalPipe) {
  }

  transform(value: number | string, ...args: unknown[]): unknown {
    const transformedValue = this.numberPipe.transform(value, '1.0-0');
    if (transformedValue) {
      return transformedValue.replace(',', '. ') + " Ft.";
    }
    return value;
  }

}
