import { PayMode } from "./place-order.dto";
import { InvoiceDataDto } from "./invoice-data.dto";
import { ShippingDataDto } from "./shipping-data.dto";
import { OrderEntityDto } from "./order-entity.dto";
import { CouponDto } from "./coupon.dto";

export enum OrderStatus {
  Start = "START",
  Failed = "FAILED",
  //Payment progress statuses
  Init = "INIT",
  Timeout = "TIMEOUT",
  Canceled = "CANCELLED",
  NotAuthorized = "NOTAUTHORIZED",
  InPayment = "INPAYMENT",
  InFraud = "INFRAUD",
  Authorized = "AUTHORIZED",
  Fraud = "FRAUD",
  Reserved = "REVERSED",
  Refund = "REFUND",
  Finished = "FINISHED",
  //additional back event statuses (probably never used)
  Success = "SUCCESS",
  Fail = "FAIL",
  Cancel = "CANCEL",
  Deleted = "DELETED"

}

export interface PlacedOrderDto {
  id?: string;
  coupon?: CouponDto;

  invoiceData: InvoiceDataDto;
  shippingData: ShippingDataDto;
  entities: OrderEntityDto[];
  payMode: PayMode;
  paymentId: string;
  totalPrice: number;
  status: OrderStatus;
  transactionId: string;
  date: string;
}
