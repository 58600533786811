import { Component, OnDestroy, OnInit } from "@angular/core";
import { DataQuery } from "../../state/data.query";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { firstValueFrom, Subscription } from "rxjs";
import { DataService } from "../../services";
import { CouponDto, Delivery, DeliveryMode, DeliveryModes, OrderEntityDto, PayMode, PlaceOrderDto } from "@frontend/data-access";
import { Router } from "@angular/router";
import { LoggerService, ModalService } from "@frontend/shared";

@Component({
  selector: "fokos-order",
  templateUrl: "./order.component.html",
  styleUrls: ["./order.component.scss"]
})
export class OrderComponent implements OnInit, OnDestroy {
  PayMode = PayMode;
  DeliveryMode = DeliveryMode;
  deliveryModes = DeliveryModes;
  sub = new Subscription();
  formGroup = new FormGroup({
    coupon: new FormControl<string | null>(null),
    invoiceData: new FormGroup({
      name: new FormControl("", Validators.required),
      company: new FormControl<string | null>(null),
      taxNumber: new FormControl<string | null>(null),
      zip: new FormControl("", Validators.required),
      city: new FormControl("", Validators.required),
      address: new FormControl("", Validators.required),
      phone: new FormControl("", Validators.required),
      email: new FormControl("", [Validators.required, Validators.email]),
      comment: new FormControl<string | null>(null)
    }),
    shippingData: new FormGroup({
      name: new FormControl("", Validators.required),
      address: new FormControl("", Validators.required),
      phone: new FormControl("", Validators.required),
      email: new FormControl("", [Validators.required, Validators.email]),
      comment: new FormControl<string | null>(null),
      delivery: new FormControl<Delivery | null>(null, Validators.required)
    }),
    payMode: new FormControl(PayMode.Cash, Validators.required)
  });
  dataIsSame = new FormControl(false);
  termsOfUseAccept = new FormControl(false, Validators.requiredTrue);
  coupon: CouponDto | null;
  basketItem: Omit<OrderEntityDto, "id"> | undefined;
  _currentStep = 1;
  set currentStep(value: number) {
    if (value > 1 && this.formGroup.controls.invoiceData.invalid) {
      return;
    }
    if (this.formGroup.controls.shippingData.value.delivery === null) {
      this.shippingControls.delivery.patchValue(DeliveryModes[2], { emitEvent: false });
    }
    if (value > 2 && this.formGroup.controls.shippingData.invalid) {
      return;
    }
    this._currentStep = value;
  }

  get currentStep() {
    return this._currentStep;
  }

  constructor(
    public dataQuery: DataQuery,
    private dataService: DataService,
    private router: Router,
    private modalService: ModalService
  ) {
  }

  ngOnInit() {
    this.dataService.getGin().subscribe();
    this.sub.add(this.dataIsSame.valueChanges.subscribe(() => {
      this.copyData();
    }));
    this.sub.add(this.formGroup.controls.invoiceData.valueChanges.subscribe(() => {
      if (this.dataIsSame.value === true) {
          this.copyData();
      }
    }));
    this.sub.add(this.shippingControls.delivery.valueChanges.subscribe((mode) => {
      if (mode?.deliveryMode === DeliveryMode.Personal) {
        this.shippingControls.address.patchValue("Fokos Gin Manufaktúra", { emitEvent: false });
        LoggerService.log("Personal", this.shippingControls.address.value);
      } else {
        this.shippingControls.address.patchValue("", { emitEvent: false });
        LoggerService.log("Other", this.shippingControls.address.value);
      }
    }));
    this.sub.add(this.shippingControls.address.valueChanges.subscribe(() => {
      LoggerService.log("address.valueChanges", this.shippingControls.address.value);
    }));
    this.sub.add(this.formGroup.controls.shippingData.valueChanges.subscribe(() => {
      this.dataIsSame.setValue(false, { emitEvent: false });
    }));
    this.sub.add(this.dataQuery.activeCoupon$.subscribe(coupon => {
      this.coupon = coupon;
      this.formGroup.controls.coupon.patchValue(coupon?.code ?? null, { emitEvent: false });
    }));
    this.sub.add(this.dataQuery.basketItem$.subscribe(item => this.basketItem = item));
  }

  private copyData(reset: boolean = true) {
    if (this.dataIsSame.value) {
      const value = this.formGroup.controls.invoiceData.value;
      this.formGroup.controls.shippingData.patchValue(
        {
          ...value,
          address: this.shippingControls.delivery.value?.deliveryMode === DeliveryMode.Personal ?
            "Fokos Gin Manufaktúra" :
            `${value.zip}. ${value.city}, ${value.address}`
        },
        { emitEvent: false });
      LoggerService.log("copyData", this.shippingControls.address.value);

    }
  }

  get invoiceControls() {
    return this.formGroup.controls.invoiceData.controls;
  }

  get shippingControls() {
    return this.formGroup.controls.shippingData.controls;
  }

  get discount() {
    return this.coupon?.discount ?? 0;
  }

  get totalPrice() {
    let total = !!this.basketItem ? this.basketItem.gin.price * this.basketItem.count : 0;
    total = total > this.discount ? total - this.discount : 0;
    return total + (this.shippingControls.delivery.value?.price ?? 0);
  }

  validateCoupon() {
    const coupon = this.formGroup.controls.coupon.value;
    if (coupon) {
      this.dataService.getCoupon(coupon).subscribe();
    }
  }

  removeCoupon() {
    this.dataService.removeCoupon();
    this.formGroup.controls.coupon.reset();
  }

  scrollTop(id: string) {
    const element = document.getElementById(id);
    if (!element) return;
    const y = element.getBoundingClientRect().top + window.scrollY - 96 - 24;
    window.scrollTo({ top: y, behavior: "auto" });
  }

  async placeOrder() {
    const basket = await firstValueFrom(this.dataQuery.basketItem$);
    if (this.formGroup.valid && this.isTermsOfUseAccepted() && basket) {
      this.dataService.postOrder(this.formGroup.value as PlaceOrderDto, basket, this.coupon, this.totalPrice).subscribe(
        result => {
          switch (this.formGroup.value.payMode) {
            case PayMode.Cash:
              this.router.navigate(["visszaigazolas", result.transactionId]);
              break;
            case PayMode.SimplePay:
              window.location.href = result.paymentUrl;
              break;
          }
        }
      );
    }
  }

  isTermsOfUseAccepted(): boolean {
    this.termsOfUseAccept.markAllAsTouched();
    return this.formGroup.value.payMode === PayMode.SimplePay ? this.termsOfUseAccept.valid : true;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
