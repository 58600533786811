import { Injectable, Renderer2, RendererFactory2, } from '@angular/core';
import { NavigationEnd, Router } from "@angular/router";
import { filter, map } from "rxjs";

@Injectable({providedIn: "root"})
export class BackgroundService {
  private renderer: Renderer2;

  color: 'bg-grey' | 'bg-primary' = 'bg-grey';
  rootElement: HTMLElement | null;

  constructor(rendererFactory: RendererFactory2,
              private router: Router,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.rootElement = document.getElementById('body');
    this.updateColor(this.router.url);

    this.router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      map(e => this.updateColor(e.url)
      )).subscribe();
  }

  private updateColor(url: string) {
    const oldColor = this.color;
    if (url.includes('visszaigazolas')) {
      this.color = 'bg-primary';
    } else {
      this.color = 'bg-grey';
    }
    if (this.rootElement) {
      this.renderer.removeClass(this.rootElement, oldColor);
      this.renderer.addClass(this.rootElement, this.color);
    }
  }
}
