import { animate, state, style, transition, trigger } from "@angular/animations";

export const openCloseAnimation = trigger('openClose', [
  transition('* => *', [
    animate('0.25s')
  ]),
  state('open', style({
    visibility: 'visible',
    opacity: 1,
  })),
  state('*, closed', style({
    visibility: 'collapse',
    opacity: 0,
    height: '0px',
    marginTop: 0
  })),
]);

export const openCloseHorizontalAnimation = trigger('openCloseHorizontal', [
  transition('* => *', [
    animate('0.25s')
  ]),
  state('openMobile', style({
    visibility: 'visible',
    opacity: 1,
    width: '100vw',
  })),
  state('open', style({
    visibility: 'visible',
    opacity: 1,
    width: '450px',
  })),
  state('closed', style({
    visibility: 'collapse',
    opacity: 0,
    width: '0px',
  })),
]);
export const toolbarAnimation = trigger('toolbar', [
  state('white', style({
    'background-color': 'white',
  })),
  state('primary', style({})),
  transition('* => *', animate('200ms')),
]);

export const sidebarAnimation = trigger('openCloseSidebar', [
  transition('open => *, closed => *', [
    animate('0.5s')
  ]),
  state('open', style({
    visibility: 'visible',
    opacity: 1,
    height: '100vh'
  })),
  state('void, closed', style({
    visibility: 'collapse',
    opacity: 0,
    height: '0px',
  })),
]);
