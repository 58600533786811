import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Injector,
  TemplateRef,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ModalConfig } from '../../models/modal-config.model';

@Component({
  selector: 'fokos-modal-container',
  templateUrl: './modal-container.component.html',
  styleUrls: ['./modal-container.component.scss'],
})
export class ModalContainerComponent {
  @ViewChild('modalContainer', {read: ViewContainerRef})
  private modalContainer!: ViewContainerRef;
  config: ModalConfig = {
    disableMinPadding: false,
    fullWidth: false,
    position: 'center',
  };

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
  }

  createModal<T>(
    component: Type<T>,
    config?: ModalConfig,
    injector?: Injector
  ): ComponentRef<T> {
    this.modalContainer.clear();
    const factory: ComponentFactory<T> =
      this.componentFactoryResolver.resolveComponentFactory(component);
    const createdComponent = this.modalContainer.createComponent(
      factory,
      0,
      injector
    );
    if (config) {
      this.config = config;
      if (config.fullWidth) {
        createdComponent.location.nativeElement.style.width = '100%';
      }
      if (config.fullHeight) {
        createdComponent.location.nativeElement.style.height = '100%';
      }
    }
    return createdComponent;
  }

  createModalFromTemplateRef(templateRef: TemplateRef<unknown>) {
    this.modalContainer.clear();
    const embeddedView = this.modalContainer.createEmbeddedView(templateRef);
    return embeddedView;
  }
}
