import { Component } from '@angular/core';
import { DataQuery } from "../../../../state/data.query";

@Component({
  selector: 'fokos-greeting',
  templateUrl: './greeting.component.html',
  styleUrls: ['./greeting.component.scss'],
})
export class GreetingComponent {

  constructor(public dataQuery: DataQuery) {
  }

}
