import { Attribute, Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { RadioComponent } from "../radio.component";

@Component({
  selector: 'fokos-radio-item',
  templateUrl: './radio-item.component.html',
  styleUrls: ['./radio-item.component.scss']
})
export class RadioItemComponent {
  @Input() value: any;
  @Input() disabled: boolean | string | undefined;

  constructor(
    public toggle: RadioComponent,
    private renderer: Renderer2,
    private hostElement: ElementRef,
    @Attribute('disabled') private isDisabled: string,
  ) {
  }

  public notifyParent() {
    if (!this.isButtonDisabled())
      this.toggle.selected = this.value;
  }

  isButtonDisabled() {
    return !!(
      this.isDisabled === '' ||
      this.isDisabled === 'true' ||
      this.disabled
    );
  }
}
