import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumberStepperComponent } from './number-stepper.component';
import { FormsModule } from "@angular/forms";
import { InputModule } from "../input/input.module";


@NgModule({
  declarations: [
    NumberStepperComponent
  ],
  exports: [
    NumberStepperComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    InputModule
  ]
})
export class NumberStepperModule {
}
