<div class="flex items-center">
  <div class="content py-16">
    <h1 class="mb-16">kosár áttekintés</h1>
    <ng-container *ngIf="dataQuery.basketItem$ | async as data; else empty">
      <div class="flex gap-12 flex-wrap flex-col desktop:flex-row">
        <div class="flex-1 flex gap-6">
          <img class="w-48 h-auto" src="assets/images/glass.png"/>
          <div class="flex-1 flex flex-col gap-3 palmtop:gap-6 justify-between">
            <div class="flex flex-col gap-6">
              <h2>{{data.gin.name}}</h2>
              <p>{{data.gin.capacity | fokosNumber}} L</p>
            </div>
            <fokos-number-stepper
              class="w-[110px]"
              [formControl]="formControl"
              [min]="1"
              suffix="db"
              (ngModelChange)="updateBasket(data.gin)"
            ></fokos-number-stepper>
          </div>
        </div>
        <div class="flex-1 flex flex-col gap-6">
          <p>A szállítási költséget a pénztárban számítjuk ki.</p>
          <p>Az ár tartalmazza az ÁFA-t.</p>
          <div class="flex-col flex gap-6">
            <div class="flex justify-between gap-10">
              <span>részösszeg:</span><b>{{(data.gin.price * data.count) | fokosCurrency}}</b>
            </div>
            <div class="flex flex-col palmtop:flex-row items-start justify-between gap-6">
              <fokos-button color="accent" routerLink="/">vissza a főoldalra</fokos-button>
              <fokos-button [disabled]="data.count === 0" color="primary-full" routerLink="/megrendeles">tovább a fizetéshez
              </fokos-button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #empty>
      <p>A kosarad jelenleg üres.</p>
      <div class="mt-10 flex flex-col palmtop:flex-row items-start justify-between gap-6">
        <fokos-button routerLink="/" fragment="vasarlas">vissza a vásárláshoz</fokos-button>
      </div>
    </ng-template>
  </div>
</div>
