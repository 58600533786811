import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { catchError, Observable, throwError } from "rxjs";
import { ErrorModalComponent, ModalService } from "../modals";
import { LoggerService } from "../services/logger.service";


const IGNORED_PATHS = [
  "subscription/"
];

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private modalService: ModalService) {

  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMsg;
          LoggerService.log(error);
          const ignored = IGNORED_PATHS.some(p => error.url?.includes(p))
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
          } else if (error.error.message) {
            errorMsg = error.error.message;
          } else if (error.error.errors) {
            errorMsg = error.error.errors.join("\n");
          }
          if (!ignored && errorMsg)
            this.modalService.open<ErrorModalComponent, string, boolean>(ErrorModalComponent, errorMsg);
          return throwError(error);
        })
      );
  }
}
