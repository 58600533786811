import {
  ApplicationRef,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  OnDestroy,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { ModalContainerComponent } from './components/modal-container/modal-container.component';
import { Modal, ModalConfig, ModalRef } from './models';
import { Observable, Subscription } from 'rxjs';
import { LoadingModalComponent } from "../modals";

@Injectable()
export class ModalService implements OnDestroy {
  private modalContainerFactory!: ComponentFactory<ModalContainerComponent>;
  private viewContainer!: ViewContainerRef;

  modalRefs = new Set<ModalRef<any, any>>();
  openModalRefId!: string;
  subscriptions = new Subscription();
  body: HTMLElement | null;

  constructor(
    private appRef: ApplicationRef,
    private factoryResolver: ComponentFactoryResolver,
  ) {
    this.setupModalContainerFactory();
    this.body = document.getElementById("body");
  }

  setViewContainerRef(viewContainerRef: ViewContainerRef) {
    this.viewContainer = viewContainerRef;
  }

  show<I, R>(
    component: Type<Modal<I, R>>,
    inputs?: I,
    config?: ModalConfig
  ): Observable<R> {
    return this.open(component, inputs, config);
  }

  open<T extends Modal<I, R>, I, R>(
    component: Type<T>,
    inputs?: I,
    config?: ModalConfig
  ): Observable<R> {
    const modalRef = this.openWithRef<T, I, R>(component, inputs, config);
    return modalRef.onClose as Observable<R>;
  }

  private createAndSaveModalRef<I, R>(
    modalContainerRef: ComponentRef<ModalContainerComponent>,
    modalComponentRef: ComponentRef<any>
  ) {
    const modalRef = new ModalRef<I, R>(modalContainerRef, modalComponentRef);
    this.modalRefs.add(modalRef);

    this.subscriptions.add(
      modalRef.onClose.subscribe(() => {
        if (this.body) {
          this.body.style.overflow = 'auto';
        }
        this.modalRefs.delete(modalRef);
      })
    );

    return modalRef;
  }

  openWithRef<T extends Modal<I, R>, I, R>(
    component: Type<T>,
    inputs?: I,
    config?: ModalConfig
  ): ModalRef<I, R> {
    const modalContainerRef = this.createAndInjectModalContainer();

    const modalComponentRef = modalContainerRef.instance.createModal(
      component,
      config,
      this.viewContainer.injector
    );

    if (inputs) {
      modalComponentRef.instance.onInjectInputs(inputs);
    }

    const modalRef = this.createAndSaveModalRef<I, R>(
      modalContainerRef,
      modalComponentRef
    );

    if (this.body) {
      this.body.style.overflow = "hidden";
    }
    modalRef.onClose.subscribe(() => {
        if (this.body) {
          this.body.style.overflow = "auto";
        }
      }
    );

    return modalRef;
  }

  openLoadingModal(){
    return this.openWithRef<LoadingModalComponent, void, void>(LoadingModalComponent);
  }


  closeAllModalHandler() {
    for (const item of this.modalRefs.values()) {
      item.close();
    }

    this.modalRefs.clear();
  }

  private createAndInjectModalContainer() {
    const modalContainerRef = this.modalContainerFactory.create(
      this.viewContainer.injector
    );

    const modalContainerInjectedRef = this.viewContainer.insert(
      modalContainerRef.hostView
    );
    modalContainerInjectedRef.detectChanges();

    return modalContainerRef;
  }

  private setupModalContainerFactory(): void {
    this.modalContainerFactory = this.factoryResolver.resolveComponentFactory(
      ModalContainerComponent
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
