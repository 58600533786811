<fokos-zig-zag [top]="true" color="dark"></fokos-zig-zag>
<div class="section bg-primary-dark">
  <div class="content flex gap-6">
    <div class="flex flex-col gap-6 flex-1 text-white">
      <h2 class="text-primary-light">Megdobhatunk egy meglepivel? <br> Iratkozz fel hírlevelünkre!</h2>
      <div class="flex flex-col gap-6 self-center w-full palmtop:w-[500px]">
        <fokos-input color="accent" [error]="form.controls.name.touched && form.controls.name.invalid">
          <input [formControl]="form.controls.name" type="text" placeholder="név" required />
        </fokos-input>
        <fokos-input color="accent" [error]="form.controls.email.touched && form.controls.email.invalid">
          <input [formControl]="form.controls.email" type="text" placeholder="email-cím" required />
        </fokos-input>
        <fokos-checkbox [formControl]="dataManagement">
          <div class="leading-8">elfogadom az <a class="light" href="assets/docs/Fokos_Adatkezelesi.pdf" target="_blank">adatkezelési
            tájékoztatóban</a> foglaltakat
          </div>
          <ng-container *ngIf="dataManagement.errors && dataManagement.touched">
            <div class="mt-2 text-primary-light">Kötelező elfogadni!</div>
          </ng-container>
        </fokos-checkbox>
        <fokos-button color="accent" class="self-start"
                      (click)="subscribe()">{{subscribed ? 'feliratkozva' : 'feliratkozás'}}</fokos-button>
      </div>
    </div>
  </div>
</div>
