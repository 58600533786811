<div class="bg-grey-dark flex flex-col gap-6 palmtop:gap-12 flex-wrap p-6 palmtop:py-12 text-sm font-semibold">
  <div
    class="flex flex-col items-center tablet:justify-center gap-3 tablet:gap-20 content tablet:flex-wrap tablet:flex-row">
    <fokos-footer-item>
      <a class="no-style uppercase" href="assets/docs/Fokos_ASZF.pdf" target="_blank">ÁSZF</a>
    </fokos-footer-item>
    <fokos-footer-item>
      <a class="no-style uppercase" href="assets/docs/Fokos_Impresszum.pdf" target="_blank">Impresszum</a>
    </fokos-footer-item>
    <fokos-footer-item>
      <a class="no-style uppercase" href="assets/docs/Fokos_Adatkezelesi.pdf" target="_blank">Adatkezelési
        tájékoztató</a>
    </fokos-footer-item>
  </div>
  <div class="text-center text-white">@ 2022 Fokos Gin Manufaktúra</div>
</div>
