<div
  class="fixed bg-opacity-50 bg-neutral-dark inset-0 z-50"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
>
  <div
    class="flex justify-center h-full sm:block sm:p-0 overflow-auto"
    [ngClass]="{
      'items-center': config.position === 'center',
      'items-start': config.position === 'top',
      'items-end': config.position === 'bottom',
      'justify-center': config.position !== 'right',
      'justify-end': config.position === 'right',
      'w-full': config.fullWidth
    }"
  >
    <ng-template #modalContainer></ng-template>
    <div
      class="fixed inset-0 bg-gray-500 bg-opacity-75 place-behind"
      aria-hidden="true"
    ></div>

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span
      class="hidden sm:inline-block sm:align-middle sm:h-screen"
      aria-hidden="true"
    >&#8203;</span
    >
  </div>
</div>
