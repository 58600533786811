import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";
import { openCloseAnimation } from "@frontend/shared";
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'fokos-ingredients',
  templateUrl: './ingredients.component.html',
  styleUrls: ['./ingredients.component.scss'],
  animations: [openCloseAnimation],
})
export class IngredientsComponent implements OnInit, OnDestroy {
  ARROW_UP = "assets/icons/arrow-up.svg";
  ARROW_DOWN = "assets/icons/arrow-down.svg";
  showMore = true;
  sub = new Subscription();

  animationState(state: boolean) {
    return state ? 'open' : 'closed';
  }


  constructor(public breakpointObserver: BreakpointObserver) {
  }


  ngOnInit() {
    this.sub.add(this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
    ]).subscribe((state: BreakpointState) => {
      if (state.breakpoints[Breakpoints.XSmall]) {
        this.showMore = false;
      }
      if (state.breakpoints[Breakpoints.Small]) {
        this.showMore = true;
      }
    }));
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
