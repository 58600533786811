import { Component } from '@angular/core';
import { Modal } from "../../modal-service";

@Component({
  selector: 'fokos-error-modal',
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.scss']
})
export class LoadingModalComponent extends Modal<void, void> {

  onInjectInputs(input: void): void {
    return;
  }
}
