import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadioComponent } from './radio.component';
import { RadioItemComponent } from "./radio-item/radio-item.component";

@NgModule({
  declarations: [RadioComponent, RadioItemComponent],
  imports: [CommonModule],
  exports: [RadioComponent, RadioItemComponent],
})
export class RadioModule {
}
