import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarComponent } from './toolbar.component';
import { ZigZagModule } from '../zig-zag/zig-zag.module';
import { RouterModule } from '@angular/router';
import { ToolbarItemComponent } from './toolbar-item/toolbar-item.component';

@NgModule({
  declarations: [ToolbarComponent, ToolbarItemComponent],
  imports: [CommonModule, ZigZagModule, RouterModule],
  exports: [ToolbarComponent, ToolbarItemComponent],
})
export class ToolbarModule {
}
