import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'fokos-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    '(focus)': '_focusChanged(true)',
    '(blur)': '_focusChanged(false)',
  }
})
export class InputComponent {
  @Input() public color: 'primary' | 'accent' = 'primary';
  @Input() public error = false;
  @Input() public paddingOff = false;

  _focusChanged(value: boolean) {
    this._focus = value;
    this.cdr.markForCheck();
  }

  public get focus(): boolean {
    return this._focus;
  }

  public set disabled(value: boolean) {
    this._disabled = value;
    this.cdr.markForCheck();
  }

  public get disabled(): boolean {
    return this._disabled;
  }

  public set readOnly(value: boolean) {
    this._readOnly = value;
    this.cdr.markForCheck();
  }

  public get readOnly(): boolean {
    return this._readOnly;
  }

  public set required(value: boolean) {
    this._required = value;
    this.cdr.markForCheck();
  }

  public get required(): boolean {
    return this._required;
  }

  private _disabled = false;
  private _focus = false;
  private _readOnly = false;
  private _required = false;

  constructor(
    private cdr: ChangeDetectorRef,
  ) {
  }
}
