<fokos-zig-zag [top]="true"></fokos-zig-zag>
<div class="section bg-primary">
  <div *ngIf="dataQuery.gin$ | async as data" class="content flex gap-8 flex-col palmtop:flex-row">
    <div *ngIf="data.image; else placeholder" class="flex-1">
      <img class="w-full" [src]="data.image">
    </div>
    <ng-template #placeholder>
      <div class="flex-1 palmtop:min-h-[400px] border flex self-center items-center justify-center">
        placeholder
      </div>
    </ng-template>
    <div class="flex-1 flex flex-col gap-3 palmtop:gap-6 justify-end text-white">
      <h2 class="text-primary-light">{{data.name}}</h2>
      <p>{{data.alcohol}}%, {{data.capacity | fokosNumber}} L</p>
      <p>{{data.price | fokosCurrency }}</p>
      <p [innerHTML]="data.description"></p>
      <div>
        <b>tárolás</b>
        <p class="mt-6" [@openClose]="animationState(showMoreStorage)"
        >{{data.storageInstruction}}</p>
        <img class="w-4 cursor-pointer" [src]="showMoreStorage? ARROW_UP : ARROW_DOWN"
             (click)="showMoreStorage = !showMoreStorage"/>
      </div>
      <div>
        <b>szállítás</b>
        <p class="mt-6" [@openClose]="animationState(showMoreShipping)"
        >{{data.shippingInformation}}</p>
        <img class="w-4 cursor-pointer" [src]="showMoreShipping ? ARROW_UP : ARROW_DOWN"
             (click)="showMoreShipping = !showMoreShipping"/>
      </div>
      <div class="flex gap-8">
        <fokos-number-stepper
          class="w-[110px]"
          color="accent"
          [formControl]="formControl"
          [min]="1"
          suffix="db"
        ></fokos-number-stepper>
        <fokos-button color="accent" (click)="updateBasket(data)">kosárba</fokos-button>
      </div>
    </div>
  </div>
</div>
