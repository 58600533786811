<div class="content flex flex-col py-16 gap-6">
  <div class="flex gap-16 flex-col-reverse desktop:flex-row">
    <div class="hidden desktop:w-[196px] desktop:block"></div>
    <div class="flex-auto desktop:max-w-[580px]">
      <b id="stepper" class="stepper text-grey-darker flex gap-2 mb-6 cursor-pointer">
        <span (click)="scrollTop('stepper'); currentStep=1"
              [ngClass]="{
              'text-neutral-dark': currentStep === 1,
              'text-green': currentStep !== 1}"
        >számlázási adatok</span>
        <span [ngClass]="{'text-green': currentStep === 2}">></span>
        <span (click)="scrollTop('stepper'); currentStep=2"
              [ngClass]="{
                'text-neutral-dark': currentStep === 2,
                'text-green': currentStep === 3}"
        >szállítási adatok</span>
        <span [ngClass]="{'text-green': currentStep === 3}">></span>
        <span (click)="scrollTop('stepper'); currentStep = 3"
              [ngClass]="{'text-neutral-dark': currentStep === 3}"
        >fizetés</span>
      </b>
      <div class="flex flex-col gap-6" *ngIf="currentStep === 1">
        <fokos-input [error]="invoiceControls.name.invalid && invoiceControls.name.touched">
          <input [formControl]="invoiceControls.name" type="text" placeholder="név" autocomplete="name">
        </fokos-input>
        <fokos-input>
          <input [formControl]="invoiceControls.company" type="text" placeholder="cégnév (amennyiben cégként vásárol)">
        </fokos-input>
        <fokos-input>
          <input [formControl]="invoiceControls.taxNumber" type="text"
                 placeholder="adószám (amennyiben cégként vásárol)">
        </fokos-input>
        <div class="flex flex-col palmtop:flex-row gap-4">
          <fokos-input class="flex-1" [error]="invoiceControls.zip.invalid && invoiceControls.zip.touched">
            <input [formControl]="invoiceControls.zip" type="text" placeholder="irányítószám"
                   autocomplete="postal-code">
          </fokos-input>
          <fokos-input class="flex-2" [error]="invoiceControls.city.invalid && invoiceControls.city.touched">
            <input [formControl]="invoiceControls.city" type="text" placeholder="város" autocomplete="address-level2">
          </fokos-input>
        </div>
        <fokos-input [error]="invoiceControls.address.invalid && invoiceControls.address.touched">
          <input [formControl]="invoiceControls.address" type="text" placeholder="cím" autocomplete="street-address">
        </fokos-input>
        <fokos-input [error]="invoiceControls.phone.invalid && invoiceControls.phone.touched">
          <input [formControl]="invoiceControls.phone" type="text" placeholder="telefonszám" autocomplete="phone">
        </fokos-input>
        <fokos-input [error]="invoiceControls.email.invalid && invoiceControls.email.touched">
          <input [formControl]="invoiceControls.email" type="text" placeholder="email-cím" autocomplete="email">
        </fokos-input>
        <fokos-input>
          <textarea [formControl]="invoiceControls.comment" placeholder="megjegyzés"></textarea>
        </fokos-input>
        <fokos-checkbox [formControl]="dataIsSame">szállítási adatok megegyeznek a számlázásival</fokos-checkbox>
        <fokos-button class="self-end" (click)="scrollTop('stepper'); currentStep=2;">szállítási
          adatok
        </fokos-button>
      </div>
      <div class="flex flex-col gap-6" *ngIf="currentStep === 2">
        <fokos-radio class="flex flex-col gap-2" [formControl]="shippingControls.delivery">
          <fokos-radio-item *ngFor="let mode of deliveryModes"
                            [disabled]="mode.disabled"
                            [value]="mode">
            <div>
              {{mode.deliveryMode | fokosDeliveryMode}}
              <span *ngIf="mode.disabled">(hamarosan!)</span>
              <span *ngIf="!mode.disabled && mode.price > 0">({{mode.price | fokosCurrency}})</span>
            </div>
          </fokos-radio-item>
          <div class="ml-12" *ngIf="shippingControls.delivery.value?.deliveryMode === DeliveryMode.Personal">
            <p>Fokos Gin Manufaktúra</p>
            <p>1126, Budapest</p>
            <p>Szendrő utca 57. fszt. 2.</p>
          </div>
        </fokos-radio>
        <fokos-input [error]="shippingControls.name.invalid && shippingControls.name.touched">
          <input [formControl]="shippingControls.name" type="text" placeholder="átvevő neve" autocomplete="name">
        </fokos-input>
        <fokos-input *ngIf="shippingControls.delivery.value?.deliveryMode !== DeliveryMode.Personal"
                     [error]="shippingControls.address.invalid && shippingControls.address.touched">
          <input [formControl]="shippingControls.address" type="text" placeholder="szállítási cím">
        </fokos-input>
        <fokos-input [error]="shippingControls.phone.invalid && shippingControls.phone.touched">
          <input [formControl]="shippingControls.phone" type="text" placeholder="telefonszám" autocomplete="phone">
        </fokos-input>
        <fokos-input [error]="shippingControls.email.invalid && shippingControls.email.touched">
          <input [formControl]="shippingControls.email" type="text" placeholder="email-cím" autocomplete="email">
        </fokos-input>
        <fokos-input type="textarea">
          <textarea [formControl]="shippingControls.comment" placeholder="megjegyzés"></textarea>
        </fokos-input>
        <div class="flex justify-between gap-4">
          <fokos-button (click)="scrollTop('stepper'); currentStep=1;">vissza</fokos-button>
          <fokos-button (click)="scrollTop('stepper'); currentStep=3"> tovább a fizetéshez</fokos-button>
        </div>
      </div>
      <div class="flex flex-col gap-6" *ngIf="currentStep === 3">
        <fokos-radio class="flex flex-col gap-2" [formControl]="formGroup.controls.payMode">
          <fokos-radio-item [value]="PayMode.Cash">Készpénzes fizetés</fokos-radio-item>
          <fokos-radio-item [value]="PayMode.SimplePay">Kártyás fizetés</fokos-radio-item>
          <div class="mt-4 ml-12 flex flex-wrap gap-x-12 gap-y-6 items-center">
            <a href="http://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf" target="_blank">
              <img class="w-[197px] min-h-[27px]" src="assets/images/simplepay_horizontal_01.png"
                   title=" SimplePay - Online bankkártyás fizetés" alt=" SimplePay vásárlói tájékoztató">
            </a>
          </div>
        </fokos-radio>

        <ng-container *ngIf="formGroup.controls.payMode.value === PayMode.SimplePay">
          <fokos-checkbox [formControl]="termsOfUseAccept">
            <div class="text-justify">Tudomásul veszem, hogy a(z) Fokos Gin Manufaktúra Kft. (1126 Budapest, Szendrő u.
              57. fszt. 2.) adatkezelő által a fokosgin.hu felhasználói adatbázisában tárolt alábbi személyes
              adataim átadásra kerülnek az OTP Mobil Kft., mint adatfeldolgozó részére. Az adatkezelő által továbbított
              adatok köre az alábbi: név, email, telefon, számlázasi cím.<br>
              Az adatfeldolgozó által végzett adatfeldolgozási tevékenység jellege és célja a SimplePay Adatkezelési
              tájékoztatóban, az alábbi linken tekinthető meg: <a href="http://simplepay.hu/vasarlo-aff">http://simplepay.hu/vasarlo-aff</a>
            </div>
            <ng-container *ngIf="termsOfUseAccept.errors && termsOfUseAccept.touched">
              <div class="mt-2 text-primary-dark">Kötelező elfogadni!</div>
            </ng-container>
          </fokos-checkbox>

        </ng-container>
        <div class="flex justify-between gap-4">
          <fokos-button (click)="scrollTop('stepper'); currentStep=2;">vissza</fokos-button>
          <fokos-button class="w-[210px]" (click)="placeOrder()" [ngSwitch]="formGroup.controls.payMode.value">
            <span *ngSwitchCase="PayMode.SimplePay">fizetés</span>
            <span *ngSwitchCase="PayMode.Cash">rendelés leadása</span>
          </fokos-button>
        </div>
      </div>
    </div>
    <div class="flex justify-center">
      <div class="w-[196px] flex flex-col gap-6  items-stretch">
        <b>kosár tartalma</b>
        <ng-container *ngIf="dataQuery.basketItem$ | async as data">
          <img class="w-48 h-auto" src="assets/images/glass.png"/>
          <b>{{data.gin.name}} ({{data.count}})</b>
          <fokos-input>
            <input [formControl]="formGroup.controls.coupon" type="text" placeholder="kuponkód">
          </fokos-input>
          <fokos-button
            (click)="coupon ? removeCoupon() : validateCoupon()">{{coupon ? 'törlés' : 'megadás'}}</fokos-button>
        </ng-container>
        <span
          class="text-center desktop:text-left">végösszeg:<br>
            <b>{{ totalPrice  | fokosCurrency}}</b>
          </span>
      </div>
    </div>
  </div>
</div>
