import { Component } from '@angular/core';
import { Modal } from "../../modal-service";

@Component({
  selector: 'fokos-error-modal',
  templateUrl: './error-modal.component.html',
})
export class ErrorModalComponent extends Modal<string, boolean> {
  message: string;

  onInjectInputs(input: string): void {
    this.message = input;
  }
}
