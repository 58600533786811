import { Component } from "@angular/core";
import { merge, Observable, of, switchMap, tap } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { DataService } from "../../services";
import { OrderStatus, PayMode, PlacedOrderDto, SimplePayEvent, SimplePayResponse } from "@frontend/data-access";
import { Buffer } from "buffer";

@Component({
  selector: "fokos-confirmation",
  templateUrl: "./confirmation.component.html",
  styleUrls: ["./confirmation.component.scss"]
})
export class ConfirmationComponent {
  SimplePayEvent = SimplePayEvent;
  OrderStatus = OrderStatus;
  PayMode = PayMode;
  transactionId: string;

  placedOrder$: Observable<PlacedOrderDto | undefined>;
  transactionData: SimplePayResponse | undefined;

  constructor(private route: ActivatedRoute,
              private dataService: DataService) {
  }

  ngOnInit() {
    this.placedOrder$ = merge(
      this.route.paramMap.pipe(switchMap(params => {
        const selectedId = params.get("id");
        if (selectedId) {
          this.transactionId = selectedId;
          return this.dataService.getPlacedOrder(selectedId);
        } else return of(undefined);
      })),
      this.route.queryParamMap.pipe(switchMap(params => {
          const response = params.get("r");
          if (response) {
            this.transactionData = JSON.parse(Buffer.from(response, "base64").toString()) as SimplePayResponse;
          }
          if (this.transactionData) {
            this.transactionId = this.transactionData.o;
            return this.dataService.getPlacedOrder(this.transactionData.o);
          } else return of(undefined);
        })
      )
    ).pipe(
      tap((order) => {
        console.log(order?.status);
        if (order && (order.status === OrderStatus.Success || order.status === OrderStatus.Finished)) {
          this.dataService.clearOrder();
        } else if (order && (order.status === OrderStatus.Init || order.status === OrderStatus.Start || order.status === OrderStatus.InPayment)) {

        }
      })
    );
  }
}
