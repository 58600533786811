export enum SimplePayEvent {
  success = "SUCCESS",
  fail = "FAIL",
  timeout = "TIMEOUT",
  cancel = "CANCEL"
}

export interface SimplePayResponse {
  r: number;
  t: number;
  e: SimplePayEvent;
  m: string;
  o: string;
}
