import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class LoggerService {

  static PRODUCTION = true;

  static log(message?: any, ...optionalParams: any[]) {
    if (!this.PRODUCTION)
      console.log(message, optionalParams);
  }
}
