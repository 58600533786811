<div class="bg-primary full-height flex">
  <div class="content self-center text-white mt-10" *ngIf="placedOrder$| async as order">
    <ng-container *ngIf="transactionData; else noTransactionData">
      <ng-container [ngSwitch]="transactionData.e">
        <ng-container *ngSwitchCase="SimplePayEvent.success">
          <h3>Sikeres tranzakció.</h3>
          <h1 class="mb-10">Köszönjük a vásárlást!</h1>
          <div class="flex flex-col tablet:grid tablet:grid-cols-2-auto gap-x-4 gap-y-2">
            <p>SimplePay tranzakció azonosító:</p>
            <p>{{transactionData.t}}</p>
            <p>Teljes összeg:</p>
            <p>{{order.totalPrice | fokosCurrency}}</p>
            <p>Rendelés azonosító:</p>
            <p>{{order.transactionId}}</p>
          </div>
          <div class="flex justify-start my-10">
            <fokos-button color="accent" routerLink="/">Vissza a főoldalra</fokos-button>
          </div>

          <div class="flex justify-center my-10">
            <a class="flex items-center gap-4 no-style" href="https://www.facebook.com/fokosgin" target="_blank">
              <img class="w-16 h-16" src="assets/images/fb-logo.svg" alt="fb-logo" />
              <p>facebook.com/fokosgin</p>
            </a>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="SimplePayEvent.fail">
          <h3>Sikertelen tranzakció.</h3>
          <h1 class="mb-10">Kérlek próbálkozz újra!</h1>
          <div class="mb-10 flex flex-col tablet:grid tablet:grid-cols-2-auto gap-x-4 gap-y-2">
            <p>SimplePay tranzakció azonosító:</p>
            <p>{{transactionData.t}}</p>
          </div>
          <p>Kérlek ellenőrizd a tranzakció során megadott adatok helyességét.
            Amennyiben minden adatot helyesen adtál meg, a visszautasítás
            okának kivizsgálása érdekében kérlek, lépj kapcsolatba a
            kártyakibocsátó bankoddal.</p>
          <div class="flex justify-start">
            <fokos-button color="accent" routerLink="/megrendeles">Vissza a megrendeléshez</fokos-button>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="SimplePayEvent.cancel">
          <h3>Megszakított fizetés.</h3>
          <h1 class="mb-10">Kérlek próbálkozz újra!</h1>
          <div class="flex flex-col tablet:grid tablet:grid-cols-2-auto gap-x-4 gap-y-2">
            <p>SimplePay tranzakció azonosító:</p>
            <p>{{transactionData.t}}</p>
          </div>
          <div class="flex justify-start">
            <fokos-button color="accent" routerLink="/megrendeles">Vissza a megrendeléshez</fokos-button>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="SimplePayEvent.timeout">
          <h3>Időtúllépés.</h3>
          <h1 class="mb-10">Kérlek próbálkozz újra!</h1>
          <div class="mb-16 flex flex-col tablet:grid tablet:grid-cols-2-auto gap-x-4 gap-y-2">
            <p>SimplePay tranzakció azonosító:</p>
            <p>{{transactionData.t}}</p>
          </div>
          <div class="flex justify-start">
            <fokos-button color="accent" routerLink="/megrendeles">Vissza a megrendeléshez</fokos-button>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-template #noTransactionData>
      <ng-container *ngIf="order.status === OrderStatus.Success || order.status === OrderStatus.Finished">
        <h3>Sikeres rendelés.</h3>
        <h1 class="mb-10">Köszönjük a vásárlást!</h1>
        <div class="flex flex-col tablet:grid tablet:grid-cols-2-auto gap-x-4 gap-y-2">
          <p>Fizetési mód:</p>
          <p>{{order.payMode | fokosPayMode}}</p>
          <ng-container *ngIf="order.payMode === PayMode.SimplePay">
            <p>SimplePay tranzakció azonosító:</p>
            <p>{{order.paymentId}}</p>
          </ng-container>
          <p>Szállítási mód:</p>
          <p>{{order.shippingData.delivery.deliveryMode | fokosDeliveryMode}}</p>
          <p>Teljes összeg:</p>
          <p>{{order.totalPrice | fokosCurrency}}</p>
          <p>Rendelés azonosító:</p>
          <p>{{order.transactionId}}</p>
        </div>
        <div class="flex justify-start my-10">
          <fokos-button color="accent" routerLink="/">Vissza a főoldalra</fokos-button>
        </div>

        <div class="flex justify-center my-10">
          <a class="flex items-center gap-4 no-style" href="https://www.facebook.com/fokosgin" target="_blank">
            <img class="w-16 h-16" src="assets/images/fb-logo.svg" alt="fb-logo" />
            <p>facebook.com/fokosgin</p>
          </a>
        </div>
      </ng-container>
    </ng-template>
  </div>
</div>
