import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: 'fokos-radio',
  templateUrl: './radio.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioComponent),
      multi: true,
    },
  ],
})
export class RadioComponent {
  private _selected: any;

  set selected(val: any) {
    this._selected = val;
    this.onChange(val);
    this.onTouch(val);
  }

  get selected() {
    return this._selected;
  }

  onChange: any = () => {
  };
  onTouch: any = () => {
  };

  writeValue(value: any): void {
    this._selected = value;
  }

  registerOnChange(fn: (_: unknown) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

}
