import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { DataQuery } from "../../state/data.query";
import { FormControl, Validators } from "@angular/forms";
import { first, Subscription } from "rxjs";
import { DataService } from "../../services";
import { GinDto } from "@frontend/data-access";
import { notNullFilter } from "@frontend/shared";

@Component({
  selector: 'fokos-basket-overview',
  templateUrl: './basket-overview.component.html',
  styleUrls: ['./basket-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BasketOverviewComponent implements OnInit, OnDestroy {
  sub = new Subscription();
  formControl = new FormControl(1, {nonNullable: true, validators: Validators.required});

  constructor(public dataQuery: DataQuery,
              private dataService: DataService) {
  }

  ngOnInit() {
    this.dataService.getGin().subscribe();
    this.sub.add(this.dataQuery.basketItem$.pipe(first(), notNullFilter()).subscribe(
      item => this.formControl.patchValue(item.count, {emitEvent: false})
    ));
  }

  updateBasket(gin: GinDto) {
    this.dataService.updateBasket(gin, this.formControl.value);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
