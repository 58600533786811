<div class="bg-primary full-height flex">
  <div *ngIf="success === undefined" class="content self-center text-white">
    <h3>{{email}}</h3>
    <h1 class="mb-10">Leiratkozás a folyamatban...</h1>
  </div>
  <div *ngIf="success" class="content self-center text-white">
    <h3>{{email}}</h3>
    <h1 class="mb-10">Sikeresen leiratkoztál a hírleveleinkről.</h1>
  </div>
  <div *ngIf="success === false" class="content self-center text-white">
    <h3>{{email}}</h3>
    <h1 class="mb-10">{{error}}</h1>
  </div>
</div>
