<fokos-input [color]="color" [error]="error">
  <span *ngIf="title" fokosInputTitle>{{title}}</span>
  <span *ngIf="prefix" fokosInputPrefix>{{prefix}}</span>
  <input type="number"
         [(ngModel)]="value"
         [placeholder]="placeholder??''"
         [min]="min"
         [max]="max"
  >
  <div fokosNumberInputStepper class="arrows flex flex-col gap-1 justify-center user-none">
    <svg class="cursor-pointer" (click)="increment(step)" width="16" height="10" viewBox="0 0 8 5"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M7.5 4.5H0.5L4 1L7.5 4.5Z" fill="currentColor" stroke="currentColor" stroke-linecap="square"
            stroke-linejoin="round"/>
    </svg>
    <svg class="cursor-pointer" (click)="increment(-step)" width="16" height="10" viewBox="0 0 8 5" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M0.5 0.5L7.5 0.5L4 4L0.5 0.5Z" fill="currentColor" stroke="currentColor" stroke-linecap="square"
            stroke-linejoin="round"/>
    </svg>
  </div>
  <span *ngIf="suffix" fokosInputSuffix>{{suffix}}</span>

</fokos-input>
