<div class="section intro desktop:min-h-[600px]">
  <div class="content pb-32 phone:pt-[140px]">
    <div *ngIf="dataQuery.greeting$ | async as data"
         class="flex flex-col gap-6 justify-center items-start palmtop:w-2/5 w-full text-white">
      <h1>{{data.title}}</h1>
      <p [innerHTML]="data.description"></p>
      <fokos-button color="accent" routerLink="" fragment="vasarlas">shop</fokos-button>
    </div>
  </div>
</div>
