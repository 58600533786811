import { Injectable } from "@angular/core";
import { AboutDto, ContactUsDto, CouponDto, GinDto, GreetingDto, OrderEntityDto, OrderResponse, PlacedOrderDto, PlaceOrderDto, Response, SubscriptionDto } from "@frontend/data-access";
import { HttpClient } from "@angular/common/http";
import { catchError, firstValueFrom, map, Observable, shareReplay, tap } from "rxjs";
import { DataStore } from "../state/data.store";
import { GoogleAnalyticsService, ModalService } from "@frontend/shared";

@Injectable({ providedIn: "root" })
export class DataService {

  constructor(private http: HttpClient,
              private store: DataStore,
              private modalService: ModalService,
              private googleAnalyticsService: GoogleAnalyticsService
  ) {
  }

  async getAll() {
    await firstValueFrom(this.getGreeting());
    await firstValueFrom(this.getAbout());
    await firstValueFrom(this.getGin());
  }

  getGreeting(): Observable<GreetingDto> {
    return this.http.get<Response<GreetingDto>>("api/greeting").pipe(
      tap(response => this.store.update({ greeting: response.result })),
      map(res => res.result)
    );
  }

  getAbout(): Observable<AboutDto> {
    return this.http.get<Response<AboutDto>>("api/about").pipe(
      tap(response => this.store.update({ about: response.result })),
      map(res => res.result)
    );
  }

  getGin(): Observable<GinDto> {
    return this.http.get<Response<GinDto>>("api/gin").pipe(
      tap(response => this.store.update({ ginDetails: response.result })),
      map(res => res.result),
      tap(response => this.updateGinInBasket(response))
    );
  }

  getCoupon(coupon: string): Observable<CouponDto> {
    return this.http.get<Response<CouponDto>>("api/coupon/" + coupon).pipe(
      tap(response => this.store.update({ activeCoupon: response.result })),
      map(res => res.result)
    );
  }

  removeCoupon() {
    this.store.update({ activeCoupon: undefined });
  }

  postOrder(dto: PlaceOrderDto, basketItem: Omit<OrderEntityDto, "id">, coupon: CouponDto | null, totalPrice: number) {
    const body: PlaceOrderDto = {
      ...dto,
      coupon,
      totalPrice: Number.parseInt(totalPrice.toFixed()),
      entities: [
        {
          ginId: basketItem.gin.id,
          count: basketItem.count
        }
      ]
    };
    const modalRef = this.modalService.openLoadingModal();
    return this.http.post<Response<OrderResponse>>("api/order", body).pipe(
      map(res => res.result),
      tap(res => {
        this.googleAnalyticsService.purchaseEventEmitter(
          res?.transactionId ?? "almafa",
          Number.parseInt(totalPrice.toFixed()),
          dto.coupon?.code ?? "",
          0,
          [{
            item_id: basketItem.gin.id,
            item_name: basketItem.gin.name,
            item_brand: "Fokos",
            item_category: "Gin",
            price: basketItem.gin.price,
            quantity: basketItem.count
          }]
        );
      }),
      tap(() => modalRef.close()),
      catchError(e => {
        modalRef.close();
        throw e;
      }),
      shareReplay()
    );
  }

  getPlacedOrder(id: string) {
    return this.http.get<PlacedOrderDto>("api/order/" + id);
  }

  subscribe(dto: SubscriptionDto) {
    const modalRef = this.modalService.openLoadingModal();
    return this.http.post<SubscriptionDto>("api/subscription", dto).pipe(
      tap(() => modalRef.close()),
      catchError(e => {
        modalRef.close();
        throw e;
      }),
      shareReplay()
    );
  }

  unsubscribe(email: string) {
    return this.http.delete<SubscriptionDto>("api/subscription/" + email);
  }

  contact(dto: ContactUsDto) {
    return this.http.post<ContactUsDto>("api/contact-us", dto);
  }

  updateGinInBasket(gin: GinDto) {
    this.store.update(state => {
      const item = state.basket.find(item => item.gin.id === gin.id);
      if (item) item.gin = gin;
    });
  }

  updateBasket(gin: GinDto, count: number, increment: boolean = false) {
    let addedCount = 0;
    this.store.update(state => {
      const item = state.basket.find(item => item.gin.id === gin.id);
      if (item) {
        item.count = increment ? item.count + count : count;
        addedCount = increment ? count : count - item.count;
      } else {
        state.basket.push({ gin: gin, count: count });
        addedCount = count;
      }
    });
    this.googleAnalyticsService.cartEventEmitter(
      addedCount > 0 ? "add_to_cart" : "remove_from_cart",
      gin.price * count,
      [{
        item_id: gin.id,
        item_name: gin.name,
        item_brand: "Fokos",
        item_category: "Gin",
        price: gin.price,
        quantity: Math.abs(addedCount)
      }]
    );
  }

  set over18(value: boolean) {
    this.store.update(state => {
      state.over18 = value;
    });
  }

  set cookiesAccepted(value: boolean) {
    this.store.update(state => {
      state.cookiesAccepted = value;
    });
  }

  clearOrder() {
    this.removeCoupon();
    this.store.update(state => {
      state.basket = [];
    });
  }
}
