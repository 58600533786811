import { Pipe, PipeTransform } from '@angular/core';
import { DeliveryMode } from "@frontend/data-access";

@Pipe({
  name: 'fokosDeliveryMode'
})
export class DeliveryModePipe implements PipeTransform {

  transform(mode: DeliveryMode, ...args: unknown[]): string {
    switch (mode) {
      case DeliveryMode.GLS:
        return "GLS";
      case DeliveryMode.Fokos:
        return "Házhozszállítás";
      case DeliveryMode.Foxpost:
        return "Foxpost";
      case DeliveryMode.Personal:
        return "Személyes átvétel";
      default:
        return '';
    }
  }

}
